import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@hapara/ui/src/atomic/Button";

interface GuidedBrowsingSessionDrawerActionsProps {
  mode:
    | "edit"
    | "create"
    | "start"
    | "schedule"
    | "create-template"
    | "schedule-template"
    | "start-student";
  sessionType: "filter" | "focus";
  onBack: () => void;
  onSchedule: () => void;
  onSaveAndClose: () => void;
  onStart: () => void;
  onUpdateTemplate: () => void;
  onCancelSchedule: () => void;
  isUpdating?: boolean;
  isStartButtonDisabled: boolean;
  isSaveTemplateButtonDisabled: boolean;
  isScheduleButtonDisabled: boolean;
  isUpdateTemplateButtonDisabled: boolean;
  isConfirmScheduleButtonDisabled?: boolean;
}

export const GuidedBrowsingSessionDrawerActions: React.FC<
  GuidedBrowsingSessionDrawerActionsProps
> = ({
  mode,
  sessionType,
  onBack,
  onSchedule,
  onSaveAndClose,
  onStart,
  onUpdateTemplate,
  onCancelSchedule,
  isUpdating,
  isStartButtonDisabled,
  isSaveTemplateButtonDisabled,
  isScheduleButtonDisabled,
  isUpdateTemplateButtonDisabled,
  isConfirmScheduleButtonDisabled,
}) => {
  const intl = useIntl();

  const renderCancelButton = () => (
    <Button
      dataTestId={
        mode === "schedule"
          ? "guided-browsing-cancel-schedule-session"
          : "guided-browsing-cancel"
      }
      label={intl.formatMessage({
        defaultMessage: "Cancel",
        id: "47FYwb",
      })}
      type="outlined"
      outlineType="solid"
      onClick={mode === "schedule" ? onCancelSchedule : onBack}
    />
  );

  const renderScheduleButton = () => (
    <Button
      dataTestId={`guided-browsing-schedule-${sessionType}-session`}
      isDisabled={isScheduleButtonDisabled}
      label={intl.formatMessage({
        defaultMessage: "Schedule",
        id: "hGQqkW",
      })}
      type="secondary"
      outlineType="solid"
      onClick={onSchedule}
    />
  );

  const renderConfirmScheduleButton = () => (
    <Button
      dataTestId="guided-browsing-confirm-schedule-session"
      label={intl.formatMessage({
        defaultMessage: "Schedule",
        id: "hGQqkW",
      })}
      type="primary"
      outlineType="solid"
      onClick={onSchedule}
      isDisabled={isConfirmScheduleButtonDisabled}
    />
  );

  const renderSaveTemplateButton = () => (
    <Button
      dataTestId={`guided-browsing-save-${sessionType}-template`}
      isDisabled={isSaveTemplateButtonDisabled}
      label={intl.formatMessage({
        defaultMessage: "Save",
        id: "jvo0vs",
      })}
      type="secondary"
      outlineType="solid"
      onClick={onSaveAndClose}
    />
  );

  const renderStartButton = () => (
    <Button
      dataTestId="guided-browsing-start-session"
      label={intl.formatMessage({
        defaultMessage: "Start",
        id: "mOFG3K",
      })}
      type="primary"
      outlineType="solid"
      onClick={onStart}
      isDisabled={isStartButtonDisabled}
    />
  );

  const renderSaveChangesButton = () => (
    <Button
      dataTestId="guided-browsing-update-template"
      label={intl.formatMessage({
        defaultMessage: "Save changes",
        id: "X0ha1a",
      })}
      type="primary"
      outlineType="solid"
      isLoading={isUpdating}
      isDisabled={isUpdateTemplateButtonDisabled}
      onClick={onUpdateTemplate}
    />
  );

  return (
    <>
      {renderCancelButton()}
      {mode === "create" && (
        <>
          {renderScheduleButton()}
          {renderStartButton()}
        </>
      )}
      {mode === "edit" && renderSaveChangesButton()}
      {mode === "start" && renderStartButton()}
      {mode === "schedule" && renderConfirmScheduleButton()}
      {mode === "schedule-template" && renderScheduleButton()}
      {mode === "create-template" && renderSaveTemplateButton()}
      {mode === "start-student" && (
        <>
          {renderScheduleButton()}
          {renderStartButton()}
        </>
      )}
    </>
  );
};
