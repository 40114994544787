import { useReducer } from "react";
import {
  ChatState,
  ChatFeatureFlags,
  ChatClassStatus,
  ChatContactPresence,
  ChatTextMessageState,
} from "../types/state";
import {
  ChatContactsResponse,
  ChatChannelHistoryResponse,
  ChatAccountResponse,
} from "../types/response";
// TODO: convert ui package to NodeNext
import { type ChatMessage } from "@hapara/messaging/src/types";
import { transformContacts } from "../transforms/transformContacts";

type ChatAction =
  | { type: "SET_CONNECTION_STATUS"; payload: ChatState["connectionStatus"] }
  | { type: "SET_IS_SCHOOL_HOURS"; payload: boolean }
  | { type: "SET_USER_ID"; payload: string }
  | { type: "RECEIVE_CONTACTS"; payload: ChatContactsResponse["contacts"] }
  | { type: "SELECT_CHANNEL"; payload: string | null }
  | { type: "SELECT_CLASS"; payload: string | null }
  | {
      type: "SET_CLASS_STATUS";
      payload: { classId: string; userId: string; status: ChatClassStatus };
    }
  | {
      type: "RECEIVE_CHANNELS";
      payload: ChatState["channels"];
    }
  | {
      type: "RECEIVE_CHANNEL_HISTORY";
      payload: {
        channelId: string;
        messages: ChatChannelHistoryResponse["history"];
      };
    }
  | {
      type: "SET_CONTACT_PRESENCE";
      payload: { contactId: string; presence: ChatContactPresence };
    }
  | {
      type: "RECEIVE_CLASSES";
      payload: ChatState["classes"];
    }
  | {
      type: "RECEIVE_TEXT_MESSAGE";
      payload: {
        channelId: string;
        message: ChatMessage;
      };
    }
  | { type: "MARK_AS_READ"; payload: { channelId: string; classId: string } }
  | {
      type: "UPDATE_FEATURE_FLAGS";
      payload: ChatFeatureFlags;
    }
  | { type: "RECEIVE_ACCOUNT"; payload: ChatAccountResponse };

const actionHandlers: {
  [K in ChatAction["type"]]: (
    state: ChatState,
    action: Extract<ChatAction, { type: K }>
  ) => ChatState;
} = {
  SET_IS_SCHOOL_HOURS: (state, { payload }) => {
    return {
      ...state,
      isSchoolHours: payload,
    };
  },
  RECEIVE_ACCOUNT: (state, { payload }) => {
    const { schoolHours } = payload;
    return {
      ...state,
      schoolHours,
    };
  },
  RECEIVE_CHANNELS: (state, { payload }) => {
    return {
      ...state,
      channels: {
        ...payload,
      },
    };
  },
  SET_CLASS_STATUS: (state, { payload: { classId, userId, status } }) => {
    return {
      ...state,
      classes: {
        ...state.classes,
        [classId]: {
          ...state.classes[classId],
          status: {
            ...state.classes[classId]?.status,
            [userId]: status,
          },
        },
      },
    };
  },
  SET_CONTACT_PRESENCE: (state, { payload: { contactId, presence } }) => {
    return {
      ...state,
      contacts: {
        ...state.contacts,
        [contactId]: {
          ...state.contacts[contactId],
          presence,
        },
      },
    };
  },
  SELECT_CLASS: (state, action) => ({
    ...state,
    selectedClassId: action.payload,
  }),
  RECEIVE_CONTACTS: (state, action) => {
    const receivedContacts = action.payload;
    return {
      ...state,
      contacts: {
        ...state.contacts,
        ...transformContacts(state.contacts, receivedContacts),
      },
    };
  },
  RECEIVE_CLASSES: (state, action) => ({
    ...state,
    classes: {
      ...state.classes,
      ...action.payload,
    },
  }),
  SELECT_CHANNEL: (state, action) => ({
    ...state,
    selectedChannelId: action.payload,
  }),
  SET_USER_ID: (state, action) => ({
    ...state,
    userId: action.payload,
  }),
  SET_CONNECTION_STATUS: (state, action) => ({
    ...state,
    connectionStatus: action.payload,
  }),

  MARK_AS_READ: (state, { payload: { channelId, classId } }) => {
    const currentUnread = state.channels[channelId]?.unread?.[classId] ?? 0;
    return {
      ...state,
      totalUnread: state.totalUnread - currentUnread,
      classesUnread: {
        ...state.classesUnread,
        [classId]: (state.classesUnread?.[classId] ?? 0) - currentUnread,
      },
      channels: {
        ...state.channels,
        [channelId]: {
          ...state.channels[channelId],
          unread: {
            ...state.channels[channelId]?.unread,
            [classId]: 0,
          },
        },
      },
    };
  },
  RECEIVE_CHANNEL_HISTORY: (state, { payload: { channelId, messages } }) => {
    const existingMessages = state.channels[channelId]?.messages ?? [];
    const existingMessageIds = new Set(existingMessages.map((msg) => msg.id));

    const historyMessages = messages.filter(
      (message) =>
        message.type === "text" && !existingMessageIds.has(message.id)
    ) as ChatTextMessageState[];

    return {
      ...state,
      channels: {
        ...state.channels,
        [channelId]: {
          ...state.channels[channelId],
          retrievedHistory: true,
          messages: [...historyMessages, ...existingMessages],
        },
      },
    };
  },
  RECEIVE_TEXT_MESSAGE: (state, action) => {
    const { channelId, message } = action.payload;
    if (!channelId || message.type !== "text") return state;

    const isRecipientOfMessage = message.receiverId === state.userId;
    const currentUnread =
      state.channels[channelId]?.unread?.[message.classId] ?? 0;
    const currentTotalUnread = state.totalUnread;
    const unreadIncrement = isRecipientOfMessage ? 1 : 0;
    const currentClassUnread = state.classesUnread?.[message.classId] ?? 0;

    const existingMessages = state.channels[channelId]?.messages ?? [];
    const messageExists = existingMessages.some((msg) => msg.id === message.id);

    return {
      ...state,
      totalUnread: currentTotalUnread + unreadIncrement,
      classesUnread: {
        ...state.classesUnread,
        [message.classId]: currentClassUnread + unreadIncrement,
      },
      channels: {
        ...state.channels,
        [channelId]: {
          ...state.channels[channelId],
          unread: {
            ...state.channels[channelId]?.unread,
            [message.classId]: currentUnread + unreadIncrement,
          },
          messages: messageExists
            ? existingMessages
            : [...existingMessages, message],
        },
      },
    };
  },
  UPDATE_FEATURE_FLAGS: (state, action) => ({
    ...state,
    featureFlags: action.payload,
  }),
};

const chatReducer = (state: ChatState, action: ChatAction): ChatState => {
  const handler = actionHandlers[action.type] as (
    state: ChatState,
    action: ChatAction
  ) => ChatState;

  return handler(state, action);
};

export const initialState: ChatState = {
  connectionStatus: "disconnected",
  totalUnread: 0,
  classesUnread: {},
  channels: {},
  classes: {},
  userId: null,
  contacts: {},
  selectedChannelId: null,
  selectedClassId: null,
  featureFlags: {
    isChatSwitchEnabled: false,
  },
  schoolHours: null,
  isSchoolHours: false,
  displayNameFormat: "firstName-lastName",
};

export const useChatReducer = () => {
  const [chatState, dispatchChatState] = useReducer(chatReducer, initialState);

  const receiveAccountAction = (account: ChatAccountResponse) =>
    dispatchChatState({
      type: "RECEIVE_ACCOUNT",
      payload: account,
    });

  const receiveChannelsAction = (channels: ChatContactsResponse["channels"]) =>
    dispatchChatState({
      type: "RECEIVE_CHANNELS",
      payload: Object.keys(channels).reduce<ChatState["channels"]>(
        (acc, channelId) => {
          return {
            ...acc,
            [channelId]: {
              ...chatState.channels[channelId],
              id: channelId,
              contactId: channels[channelId].contactId,
            },
          };
        },
        {}
      ),
    });

  const selectChannelAction = (id: string | null) =>
    dispatchChatState({ type: "SELECT_CHANNEL", payload: id });

  const selectClassAction = (id: string | null) =>
    dispatchChatState({ type: "SELECT_CLASS", payload: id });

  const setConnectionStatusAction = (status: ChatState["connectionStatus"]) =>
    dispatchChatState({ type: "SET_CONNECTION_STATUS", payload: status });

  const receiveTextMessageAction = (
    channelId: string,
    message: ChatMessage
  ) => {
    dispatchChatState({
      type: "RECEIVE_TEXT_MESSAGE",
      payload: {
        channelId: channelId,
        message,
      },
    });
  };
  const receiveTextMessageHistoryAction = (
    channelId: string,
    messages: ChatChannelHistoryResponse["history"]
  ) => {
    dispatchChatState({
      type: "RECEIVE_CHANNEL_HISTORY",
      payload: {
        channelId,
        messages,
      },
    });
  };

  const receiveContactsAction = (
    contacts: ChatContactsResponse["contacts"]
  ) => {
    dispatchChatState({
      type: "RECEIVE_CONTACTS",
      payload: contacts,
    });
  };

  const receiveClassesAction = (classes?: ChatState["classes"]) => {
    dispatchChatState({
      type: "RECEIVE_CLASSES",
      payload: classes || {},
    });
  };

  const updateFeatureFlagsAction = (
    featureFlags?: ChatState["featureFlags"]
  ) => {
    dispatchChatState({
      type: "UPDATE_FEATURE_FLAGS",
      payload: {
        isChatSwitchEnabled: featureFlags?.isChatSwitchEnabled ?? false,
      },
    });
  };

  const setUserAction = (userId: string) =>
    dispatchChatState({ type: "SET_USER_ID", payload: userId });

  const setIsSchoolHours = (isSchoolHours: boolean) =>
    dispatchChatState({ type: "SET_IS_SCHOOL_HOURS", payload: isSchoolHours });

  const markChannelAsReadAction = (channelId: string, classId: string) => {
    dispatchChatState({
      type: "MARK_AS_READ",
      payload: { channelId, classId },
    });
  };

  const setClassStatusAction = (
    classId: string,
    userId: string,
    status: ChatClassStatus
  ) => {
    dispatchChatState({
      type: "SET_CLASS_STATUS",
      payload: { classId, userId, status },
    });
  };

  const setContactPresenceAction = (
    contactId: string,
    presence: ChatContactPresence
  ) => {
    dispatchChatState({
      type: "SET_CONTACT_PRESENCE",
      payload: { contactId, presence },
    });
  };

  return {
    chatState,
    setConnectionStatusAction,
    setIsSchoolHours,
    receiveChannelsAction,
    selectChannelAction,
    selectClassAction,
    setClassStatusAction,
    setContactPresenceAction,
    markChannelAsReadAction,
    receiveTextMessageAction,
    receiveTextMessageHistoryAction,
    receiveContactsAction,
    receiveAccountAction,
    receiveClassesAction,
    setUserAction,
    updateFeatureFlagsAction,
  };
};
