import { useEffect, useRef } from "react";
import { ChatRoleType } from "./types/state";
import {
  ChatContactsResponse,
  ChatAccountResponse,
  ChatChannelHistoryResponse,
} from "./types/response";
import { ChatState } from "./types/state";
import { chatEventEmitter, useEventListener } from "./chatEventEmitter";
import { useChatReducer } from "./useChatReducer";
import { AuthFetch } from "../../providers/AuthProvider/AuthProvider";
import { Messaging } from "@hapara/messaging/src/Messaging";
import { type ChatMessage } from "@hapara/messaging/src/types";
import { type ChatFeatureFlags } from "./types/state";
import { createMessage } from "@hapara/messaging/src/utils/createMessages";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { isWithinTimeRange } from "../../utils/isWithinTimeRange";
import { isDayOfWeek } from "../../utils/isDayOfWeek";
import { getCurrentDate } from "../../utils/getCurrentDate";

type ChatBackgroundOptions = {
  role: ChatRoleType;
  userId?: string;
  /** We authenticate Chrome extensions differently; the Hapara access_token is
   * retrieved using the Chrome auth token. In apps that use the AuthProvider, you
   * can simply pass in the standard authFetch. */
  authFetch: AuthFetch;
  isAuthReady: boolean;
  authToken?: string | null;
  apiUrl: string;
  classId: string | null;
  onNotification?: (notification: { message: string; title: string }) => void;
  chatFeatureFlags: ChatFeatureFlags;
};

export const useChatBackground = ({
  role,
  userId,
  authFetch,
  isAuthReady,
  apiUrl,
  classId,
  chatFeatureFlags,
  authToken,
  onNotification,
}: ChatBackgroundOptions) => {
  const messaging = useRef(Messaging.getInstance()).current;

  const {
    chatState,
    selectChannelAction,
    selectClassAction,
    receiveAccountAction,
    receiveChannelsAction,
    receiveTextMessageAction,
    receiveContactsAction,
    receiveClassesAction,
    markChannelAsReadAction,
    setUserAction,
    updateFeatureFlagsAction,
    setClassStatusAction,
    setContactPresenceAction,
    setConnectionStatusAction,
    receiveTextMessageHistoryAction,
    setIsSchoolHours,
  } = useChatReducer();
  // We currently need these refs so they're available outside of React's render
  // lifecycle i.e. the event listeners.
  const chatStateRef = useRef<ChatState>(chatState);

  useEffect(() => {
    selectClassAction(classId);
  }, [classId]);

  useEffect(() => {
    chatStateRef.current = chatState;
    chatEventEmitter.emit("CHAT_STATE_CHANGE", chatStateRef.current);
  }, [chatState]);

  useEffect(() => {
    if (chatFeatureFlags) {
      updateFeatureFlagsAction({
        isChatSwitchEnabled:
          (chatFeatureFlags as ChatFeatureFlags).isChatSwitchEnabled ?? false,
      });
    }
  }, [chatFeatureFlags]);

  // MESSAGE ROUTING
  const handleReceiveMessage = (message: ChatMessage) => {
    const { contacts, selectedClassId, classes } = chatStateRef.current;
    if (message.type === "text") {
      const contact = contacts[message.publisherId];
      if (contact) {
        // TODO: localize teacher + name order based on HL preferences
        onNotification?.({
          message: message.body,
          title:
            contact.type === "teacher"
              ? `Teacher ${contact.lastName}`
              : `${contact.firstName} ${contact.lastName}`,
        });
      }
      receiveTextMessageAction(message.channelId, message);
    }

    if (message.publisherId !== userId) {
      if (message.type === "presence") {
        if (role === "teacher" && userId && selectedClassId) {
          messaging.send({
            channelNamespace: "chat",
            channelId: message.channelId,
            type: "class-status",
            body: classes[selectedClassId]?.status?.[userId] ?? "disabled",
            classId: selectedClassId,
            publisherId: userId,
          });
        }
        setContactPresenceAction(message.publisherId, message.body);
      }
    }

    if (message.type === "class-status") {
      setClassStatusAction(message.classId, message.publisherId, message.body);
    }
  };

  const handleConnect = () => {
    if (authToken && userId && classId) {
      messaging.connect({
        channels: Object.keys(chatState.channels),
        token: authToken,
        userId,
      });
    }
  };

  const handleRequestAccount = async () => {
    const response = await authFetch<ChatAccountResponse>(
      `${apiUrl}/chat/v1/account`
    );

    if (response.ok) {
      receiveAccountAction(response.data);
    }
  };

  const handleRequestContacts = async (classId?: string | null) => {
    if (role === "teacher") {
      // Reset contacts and channels when teacher switches classes
      receiveContactsAction({});
      receiveChannelsAction({});
    }

    const response = await authFetch<ChatContactsResponse>(
      classId
        ? `${apiUrl}/chat/v1/contacts/class/${classId}`
        : `${apiUrl}/chat/v1/contacts`
    );

    if (response.ok) {
      const { contacts, channels, classes } = response.data;
      receiveContactsAction(contacts);
      receiveChannelsAction(channels);
      receiveClassesAction(classes);
    }
  };

  const handleRequestMessageHistory = async (channelId: string) => {
    if (chatState.channels[channelId].retrievedHistory !== true) {
      try {
        const response = await authFetch<ChatChannelHistoryResponse>(
          `${apiUrl}/chat/v1/history`,
          { method: "POST", body: JSON.stringify({ channelId }) }
        );

        if (response.ok) {
          receiveTextMessageHistoryAction(channelId, response.data.history);
        }
      } catch (error) {
        console.error("[chat] error fetching channel history", error);
      }
    }
  };

  useEffect(() => {
    messaging.onReceive((message) => {
      handleReceiveMessage(message);
    });
  }, [chatState.contacts]);

  useEffect(() => {
    if (chatState.selectedChannelId) {
      handleRequestMessageHistory(chatState.selectedChannelId);
    }
  }, [chatState.selectedChannelId]);

  useEffect(() => {
    // TODO: PS-1411 create a hook to reliably handle triggering events at a specific time.
    // https://github.com/amrlabib/react-timer-hook/blob/master/src/useTime.js
    const timer = setInterval(() => {
      if (chatStateRef.current.schoolHours) {
        const { end, start, saturday, sunday, timeZone } =
          chatStateRef.current.schoolHours;
        const currentTime = getCurrentTime(timeZone);
        const currentDate = getCurrentDate(timeZone);

        const isWeekday =
          !isDayOfWeek("saturday", currentDate) &&
          !isDayOfWeek("sunday", currentDate);
        const isMonitoredWeekend = !isWeekday && (saturday || sunday);

        const isSchoolHours =
          isWithinTimeRange(currentTime, start, end) &&
          (isWeekday || isMonitoredWeekend);
        setIsSchoolHours(isSchoolHours);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [chatState.schoolHours]);

  useEffect(() => {
    const channels = Object.keys(chatState.channels);
    const hasChannels = channels.length > 0;

    if (hasChannels && authToken && userId) {
      messaging.setToken(authToken);
      messaging.connect({
        channels: Object.keys(chatState.channels),
        token: authToken,
        userId,
      });
    }
  }, [authToken, userId, chatState.channels]);

  useEffect(() => {
    return () => {
      messaging.getSubscribedChannels().forEach(async (channelId) => {
        if (classId && userId && role === "teacher") {
          await messaging.send({
            channelNamespace: "chat",
            channelId: channelId,
            type: "class-status",
            body: "disabled",
            classId,
            publisherId: userId,
          });
        }
      });
    };
  }, [classId]);

  useEffect(() => {
    messaging.onConnectionStatus(setConnectionStatusAction);
    handleConnect();

    return () => {
      // Notify all channels that the class is disabled when teacher leaves
      const { selectedClassId, userId, channels } = chatStateRef.current;
      if (selectedClassId && userId && role === "teacher") {
        Object.keys(channels).forEach((channelId) => {
          messaging.send(
            createMessage({
              channelNamespace: "chat",
              channelId: channelId,
              type: "class-status",
              body: "disabled",
              classId: selectedClassId,
              publisherId: userId,
            })
          );
        });
      }
    };
  }, []);

  useEffect(() => {
    /** Ensure `classId` is available for the teacher as this now comes from the
     * Redux store and may not be available in time. */
    const isTeacherWithClassId = role === "teacher" && classId;
    const isStudent = role === "student";

    if (userId) {
      setUserAction(userId);
    }
    if (isAuthReady && authToken && (isTeacherWithClassId || isStudent)) {
      handleRequestAccount();
      handleRequestContacts(classId);
    }
  }, [userId, classId, isAuthReady, authToken]);

  useEventListener(
    "CHAT_SEND_TEXT_MESSAGE",
    ({ channelId, to, from, body }) => {
      if (chatState.selectedClassId) {
        messaging.send({
          channelNamespace: "chat",
          channelId: channelId,
          classId: chatState.selectedClassId,
          type: "text",
          publisherId: to,
          receiverId: from,
          body,
        });
      } else {
        throw new Error("Class ID is required to send a message");
      }
    },
    [chatState]
  );

  useEventListener(
    "CHAT_SELECT_CONTACT",
    (id: string | null) => {
      selectChannelAction(id);
    },
    []
  );

  useEventListener(
    "CHAT_CONVERSATION_READ",
    ({ channelId, classId }) => {
      markChannelAsReadAction(channelId, classId);
    },
    []
  );

  useEventListener(
    "CHAT_SET_CLASS_STATUS",
    ({ classId, userId, status }) => {
      if (role === "teacher") {
        Object.keys(chatState.channels).forEach((channelId) => {
          if (chatState.userId) {
            messaging.send(
              createMessage({
                channelNamespace: "chat",
                channelId,
                type: "class-status",
                body: status,
                classId,
                publisherId: chatState.userId,
              })
            );
          }
        });
      }

      setClassStatusAction(classId, userId, status);
    },
    [chatState]
  );

  useEventListener(
    "CHAT_REQUEST_STATE",
    () => {
      chatEventEmitter.emit("CHAT_STATE_CHANGE", chatStateRef.current);
    },
    []
  );

  useEventListener(
    "CHAT_SELECT_CLASS",
    (classId: string | null) => {
      selectClassAction(classId);
    },
    []
  );

  return {
    disconnect: () => {
      messaging.disconnect();
    },
    totalUnread: chatState.totalUnread,
    selectedClassUnread: classId ? chatState.classesUnread[classId] ?? 0 : 0,
  };
};
