import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { updateNotificationSession } from "src/state/highlights/notification/actions";
import { addGuideBrowsingRecentTabsHistory } from "src/state/highlights/sessions/actions";
import { hideTemplatesPanel } from "src/state/highlights/guideBrowsingTemplates/actions";
import { showSuccessToast } from "src/state/app/actions";
import {
  createNewGuideBrowsingTemplate,
  loadGuideBrowsingRecentTabsHistory,
  showGuideBrowsingModal,
  scheduleSessionGBE,
} from "src/state/highlights/sessions/actions";
import { putExistingGuideBrowsingTemplate } from "src/apiCalls/highlights";

import {
  getStudents,
  areSomeStudentsInSession,
} from "src/state/highlights/students/selectors";
import { getUserGroups } from "src/state/shared/userGroups/selectors";
import { getRecentTabsHistory } from "src/state/highlights/sessions/selectors";
import { getIsOutsideSchoolHours } from "src/state/highlights/config/selectors";
import { getUserTemplatesList } from "src/state/highlights/guideBrowsingTemplates/selectors";
import { getClassId } from "src/state/shared/selectors";
import { getMonitoringTime } from "src/state/highlights/config/selectors";
import {
  getGuideBrowsingModalSelectedStudent,
  getGuideBrowsingModalSelectedTemplate,
} from "src/state/highlights/sessions/selectors";

import { Template } from "src/state/highlights/guideBrowsingTemplates/type";
import { Student } from "src/components/partial/Highlights/GuidedBrowsing/GuidedBrowsingAudiencePicker/GuidedBrowsingAudiencePicker";
import { parseNewTemplateDataToOldFormat } from "src/state/highlights/sessions/types";
import { isDayOfWeek } from "@hapara/ui/src/utils/isDayOfWeek";
import { isWithinOneMonth } from "@hapara/ui/src/utils/isWithinOneMonth";
import { isWithinTimeRange } from "@hapara/ui/src/utils/isWithinTimeRange";
import { convertLocalDateTimeToUTCString } from "@hapara/ui/src/utils/convertLocalDateTimeToUTCString";
import { isDateToday } from "@hapara/ui/src/utils/isDateToday";

export const useGuidedBrowsingSession = ({
  onCancel,
  sessionType,
}: {
  onCancel: () => void;
  sessionType: "focus" | "filter" | "lock";
  isScheduledInitValue: boolean;
  onTemplateSelect: (template: Template) => void;
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // Selectors
  const students: Record<string, Student> = useSelector(getStudents);
  const userGroups = useSelector(getUserGroups);
  const recentTabs = useSelector(getRecentTabsHistory);
  const isOutsideSchoolHours = useSelector(getIsOutsideSchoolHours);
  const monitoringWindow = useSelector(getMonitoringTime);
  const classId = useSelector(getClassId) as string;
  const sessionTemplates = useSelector(getUserTemplatesList);
  const preSelectedStudent = useSelector(getGuideBrowsingModalSelectedStudent);
  const selectedTemplate = useSelector(getGuideBrowsingModalSelectedTemplate);
  const studentsList = Object.values(students);

  // TODO: PS-1376 use timezone aware "plain" dates and times.
  const currentDateTime = new Date();

  const year = currentDateTime.getFullYear();
  const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
  const day = String(currentDateTime.getDate()).padStart(2, "0");

  const initialDate = `${year}-${month}-${day}`;
  const initialTime = currentDateTime.toTimeString().slice(0, 5);

  // State
  const [links, setLinks] = useState<string[]>(selectedTemplate?.Links || []);
  const [focusType, setFocusType] = useState<"whole_site" | "links">(
    selectedTemplate?.FocusSessionDetails?.FocusType || "whole_site"
  );
  const [duration, setDuration] = useState<number | null>(
    selectedTemplate?.SelectedTime || null
  );
  const [isEndSessionKeepAllTabs, setIsEndSessionKeepAllTabs] = useState(
    selectedTemplate?.FocusSessionDetails?.EndSessionKeepAllTabs ?? true
  );
  const [isEndSessionRestoreOriginal, setIsEndSessionRestoreOriginal] =
    useState(
      selectedTemplate?.FocusSessionDetails?.EndSessionRestoreOriginal ?? true
    );
  const [recipientType, setRecipientType] = useState(
    preSelectedStudent ? "students" : selectedTemplate?.RecipientType || "class"
  );
  const [studentIds, setStudentIds] = useState<string[]>(
    selectedTemplate?.RecipientType === "class"
      ? studentsList.map((student) => student.Id)
      : []
  );

  const [groupUrns, setGroupUrns] = useState<string[]>([]);
  const [templateName, setTemplateName] = useState(
    selectedTemplate?.Name || ""
  );
  const [isDuplicateTemplateName, setIsDuplicateTemplateName] = useState(false);
  const [hasUpdateError, setHasUpdateError] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [isScheduleInProgress, setIsScheduleInProgress] =
    useState<boolean>(false);
  const [scheduledDate, setScheduledDate] = useState<string>(initialDate);
  const [scheduledTime, setScheduledTime] = useState<string>(initialTime);

  const [hasInvalidDate, setHasInvalidDate] = useState<boolean>(false);
  const [hasInvalidTime, setHasInvalidTime] = useState<boolean>(false);

  useEffect(() => {
    const sessionStudentIds =
      recipientType === "class"
        ? studentsList.map((student) => student.Id)
        : preSelectedStudent
        ? [preSelectedStudent.Id]
        : [];

    setStudentIds(sessionStudentIds);
  }, [recipientType]);

  useEffect(() => {
    if (groupUrns.length && recipientType === "groups") {
      const studentsInGroups = userGroups
        .filter((group) => groupUrns.includes(group.URN))
        .flatMap((group) => group.participants);

      const studentIdsInGroups: string[] = studentsInGroups
        .map((email: string) => {
          const student = studentsList.find((s) => s.Email === email);
          return student ? student.Id : null;
        })
        .filter((id: string) => id !== null);

      const uniqueStudentIds = [...new Set(studentIdsInGroups)];

      setStudentIds(uniqueStudentIds);
    }
  }, [groupUrns]);

  useEffect(() => {
    dispatch(loadGuideBrowsingRecentTabsHistory());
  }, []);

  useEffect(() => {
    const isValidSaturday =
      !isDayOfWeek("saturday", scheduledDate) ||
      (monitoringWindow?.Saturday ?? false);

    const isValidSunday =
      !isDayOfWeek("sunday", scheduledDate) ||
      (monitoringWindow?.Saturday ?? false);

    const isDateWithinMonitoringBounds =
      isWithinOneMonth(scheduledDate) && isValidSaturday && isValidSunday;

    const isSelectedTimeWithinMonitoringHours = isWithinTimeRange(
      scheduledTime,
      monitoringWindow.Start ?? "00:00",
      monitoringWindow.End ?? "23:59"
    );

    const isSelectedTimeWithinRangeToday = isDateToday(scheduledDate)
      ? isWithinTimeRange(
          scheduledTime,
          initialTime,
          monitoringWindow.End ?? "23:59"
        )
      : true;

    setHasInvalidDate(
      !isDateWithinMonitoringBounds || !isSelectedTimeWithinRangeToday
    );
    setHasInvalidTime(
      !isSelectedTimeWithinMonitoringHours ||
        !isDateWithinMonitoringBounds ||
        !isSelectedTimeWithinRangeToday
    );
  }, [scheduledDate, scheduledTime]);

  const shouldHaveValidTemplateName =
    !templateName?.trim() || isDuplicateTemplateName;
  const shouldHaveLinks =
    ["filter", "focus"].includes(sessionType) && !links.length;
  const shouldHaveStudents = recipientType === "students" && !studentIds.length;
  const shouldHaveGroups = recipientType === "groups" && !groupUrns.length;

  const areAnyStudentsInSession = useSelector(areSomeStudentsInSession);

  const isStartButtonDisabled =
    shouldHaveLinks ||
    shouldHaveStudents ||
    shouldHaveGroups ||
    isOutsideSchoolHours ||
    !duration;

  const isScheduleButtonDisabled =
    shouldHaveLinks || shouldHaveStudents || shouldHaveGroups || !duration;

  const isConfirmScheduleButtonDisabled =
    !isScheduleInProgress ||
    hasInvalidDate ||
    hasInvalidTime ||
    shouldHaveStudents ||
    shouldHaveGroups;

  const isSaveTemplateButtonDisabled =
    shouldHaveLinks || shouldHaveValidTemplateName || !duration;

  const isUpdateButtonDisabled = useMemo(() => {
    if (!selectedTemplate) return true;
    if (!templateName.trim()) return true;
    if (isDuplicateTemplateName) return true;
    if (links.length === 0) return true;

    const hasChanges =
      links.join(",") !== (selectedTemplate.Links || []).join(",") ||
      focusType !==
        (selectedTemplate.FocusSessionDetails?.FocusType || "whole_site") ||
      duration !== (selectedTemplate.SelectedTime || null) ||
      isEndSessionKeepAllTabs !==
        (selectedTemplate.FocusSessionDetails?.EndSessionKeepAllTabs ?? true) ||
      isEndSessionRestoreOriginal !==
        (selectedTemplate.FocusSessionDetails?.EndSessionRestoreOriginal ??
          true) ||
      recipientType !== (selectedTemplate.RecipientType || "class") ||
      templateName.trim() !== selectedTemplate.Name.trim();

    return !hasChanges;
  }, [
    selectedTemplate,
    links,
    focusType,
    duration,
    isEndSessionKeepAllTabs,
    isEndSessionRestoreOriginal,
    recipientType,
    templateName,
    isDuplicateTemplateName,
  ]);

  const getSessionDetails = () => {
    const sessionDetails: {
      ClassURN: string;
      Type: "focus" | "filter" | "lock";
      Duration: number | null;
      RecipientType: "class" | "students" | "groups";
      Students: string[];
      Links: string[];
      FocusSessionDetails?: {
        FocusType: "whole_site" | "links";
        EndSessionKeepAllTabs: boolean;
        EndSessionRestoreOriginal: boolean;
      };
      Groups?: string[];
      ScheduledTime?: string;
    } = {
      ClassURN: classId,
      Type: sessionType,
      Duration: duration ? duration * 60 * 1000 : null,
      RecipientType: recipientType,
      Students: studentIds,
      Links: links,
    };

    if (sessionType === "focus") {
      sessionDetails.FocusSessionDetails = {
        FocusType: focusType,
        EndSessionKeepAllTabs: isEndSessionKeepAllTabs,
        EndSessionRestoreOriginal: isEndSessionRestoreOriginal,
      };
    }

    if (recipientType === "groups") {
      sessionDetails.Groups = groupUrns;
    }

    if (isScheduleInProgress && !hasInvalidDate && !hasInvalidTime) {
      sessionDetails.ScheduledTime = convertLocalDateTimeToUTCString(
        scheduledDate,
        scheduledTime
      );
    }

    return sessionDetails;
  };

  // Handlers
  const handleSaveTemplate = () =>
    dispatch(
      createNewGuideBrowsingTemplate({
        classId,
        templateName,
        sessionData: parseNewTemplateDataToOldFormat({
          Name: templateName,
          Type: sessionType,
          Links: links,
          FocusSessionDetails: {
            FocusType: focusType,
            EndSessionKeepAllTabs: isEndSessionKeepAllTabs,
            EndSessionRestoreOriginal: isEndSessionRestoreOriginal,
          },
          RecipientType: recipientType,
          SelectedTime: duration,
        }),
      })
    );

  const handleStartSession = () => {
    const sessionDetails = getSessionDetails();
    dispatch(addGuideBrowsingRecentTabsHistory({ urls: links }));
    dispatch(
      updateNotificationSession({
        sessionDetails,
        sessionType,
      })
    );
    dispatch(hideTemplatesPanel());
    onCancel();
  };

  const handleUpdateTemplate = async () => {
    if (selectedTemplate) {
      setIsUpdating(true);
      try {
        await putExistingGuideBrowsingTemplate({
          classId,
          id: selectedTemplate.ID,
          templateName,
          sessionData: parseNewTemplateDataToOldFormat({
            Name: templateName,
            Type: sessionType,
            Links: links,
            FocusSessionDetails: {
              FocusType: focusType,
              EndSessionKeepAllTabs: isEndSessionKeepAllTabs,
              EndSessionRestoreOriginal: isEndSessionRestoreOriginal,
            },
            RecipientType: recipientType,
            SelectedTime: duration,
          }),
        });
        dispatch(
          showSuccessToast(
            intl.formatMessage({
              defaultMessage: "Template updated",
              id: "vM8mse",
            })
          )
        );
        setHasUpdateError(false);
        setIsUpdating(false);
        return true;
      } catch (error) {
        setIsUpdating(false);
        setHasUpdateError(true);
        return false;
      }
    }
    return false;
  };

  const handleScheduleSession = () => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "SCHEDULE_SESSION_FORM",
        sessionType,
        selectedTemplate,
      })
    );
  };

  const handleCancelSchedule = () => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: selectedTemplate
          ? "SCHEDULE_TEMPLATE_FORM"
          : "CREATE_SESSION_FORM",
        sessionType,
        selectedTemplate,
      })
    );
  };

  const handleConfirmSchedule = () => {
    const sessionDetails = getSessionDetails();
    dispatch(addGuideBrowsingRecentTabsHistory({ urls: links }));
    const message = intl.formatMessage({
      defaultMessage: "Session scheduled",
      id: "MXlZKE",
    });
    dispatch(
      scheduleSessionGBE({
        sessionDetails,
        classId,
        successToastMessage: message,
      })
    );
    dispatch(hideTemplatesPanel());
    onCancel();
  };

  const handleTemplateNameChange = (value: string) => {
    setTemplateName(value);
    setIsDuplicateTemplateName(
      sessionTemplates.some(
        (template) =>
          template.Name === value && template.ID !== selectedTemplate?.ID
      )
    );
  };

  const handleSetScheduledTime = (time: string) => {
    setIsScheduleInProgress(true);
    setScheduledTime(time);
  };

  const handleSetScheduledDate = (date: string) => {
    setIsScheduleInProgress(true);
    setScheduledDate(date ? date : initialDate);
  };

  const schedulingRange = {
    startOfMonitoring: monitoringWindow.Start,
    endOfMonitoring: monitoringWindow.End,
    sundayMonitoring: monitoringWindow.Sunday,
    saturdayMonitoring: monitoringWindow.Saturday,
  };

  return {
    links,
    focusType,
    duration,
    isEndSessionKeepAllTabs,
    isEndSessionRestoreOriginal,
    recipientType,
    studentIds,
    groupUrns,
    templateName,
    isStartButtonDisabled,
    isSaveTemplateButtonDisabled,
    isConfirmScheduleButtonDisabled,
    isScheduleButtonDisabled,
    isUpdateButtonDisabled,
    isDuplicateTemplateName,
    hasUpdateError,
    isUpdating,
    schedulingRange,
    areAnyStudentsInSession,
    setLinks,
    setFocusType,
    setDuration,
    setIsEndSessionKeepAllTabs,
    setIsEndSessionRestoreOriginal,
    setRecipientType,
    setStudentIds,
    setGroupUrns,
    setTemplateName: handleTemplateNameChange,
    handleScheduleSession,
    handleStartSession,
    handleSaveTemplate,
    handleUpdateTemplate,
    handleConfirmSchedule,
    handleCancelSchedule,
    handleSetScheduledTime,
    handleSetScheduledDate,
    students: studentsList,
    userGroups,
    recentTabs,
    scheduledTime,
    scheduledDate,
    hasInvalidDate,
    hasInvalidTime,
  };
};
